class LoadSpinner extends HTMLElement {
  connectedCallback() {
    this.render();
  }

  render() {
    this.innerHTML = `
        <div class="loadingio-spinner-ellipsis-3qhrin45tn2"><div class="ldio-det8guq8cao">
        <div></div><div></div><div></div><div></div><div></div>
        </div></div>
        <style type="text/css">
        @keyframes ldio-det8guq8cao {
        0% { transform: translate(6px,40px) scale(0); }
        25% { transform: translate(6px,40px) scale(0); }
        50% { transform: translate(6px,40px) scale(1); }
        75% { transform: translate(40px,40px) scale(1); }
        100% { transform: translate(74px,40px) scale(1); }
        }
        @keyframes ldio-det8guq8cao-r {
        0% { transform: translate(74px,40px) scale(1): }
        100% { transform: translate(74px,40px) scale(0); }
        }
        @keyframes ldio-det8guq8cao-c {
        0% { background: #54c9ef }
        25% { background: #54c9ef }
        50% { background: #54c9ef }
        75% { background: #54c9ef }
        100% { background: #54c9ef }
        }
        .ldio-det8guq8cao div {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transform: translate(40px,40px) scale(1);
        background: #54c9ef;
        animation: ldio-det8guq8cao 1s infinite cubic-bezier(0,0.5,0.5,1);
        }
        .ldio-det8guq8cao div:nth-child(1) {
        background: #54c9ef;
        transform: translate(74px,40px) scale(1);
        animation: ldio-det8guq8cao-r 0.25s infinite cubic-bezier(0,0.5,0.5,1), ldio-det8guq8cao-c 1s infinite step-start;
        }.ldio-det8guq8cao div:nth-child(2) {
        animation-delay: -0.25s;
        background: #54c9ef;
        }.ldio-det8guq8cao div:nth-child(3) {
        animation-delay: -0.5s;
        background: #54c9ef;
        }.ldio-det8guq8cao div:nth-child(4) {
        animation-delay: -0.75s;
        background: #54c9ef;
        }.ldio-det8guq8cao div:nth-child(5) {
        animation-delay: -1s;
        background: #54c9ef;
        }
        .loadingio-spinner-ellipsis-3qhrin45tn2 {
        width: 50px;
        height: 50px;
        display: inline-block;
        overflow: hidden;
        background: #ffffff;
        }
        .ldio-det8guq8cao {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(0.5);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
        }
        .ldio-det8guq8cao div { box-sizing: content-box; }
        /* generated by https://loading.io/ */
        </style>
      `;
  }
}

customElements.define('load-spinner', LoadSpinner);
